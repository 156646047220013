import axios from "axios";
import React, { useEffect, useState } from "react";

const Discounts = () => {
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [newDiscount, setNewDiscount] = useState({
    code: "",
    type: "pysyvä", // Default type
    category: "", // New field for liittymismaksut, vaihtomaksut, or 3v 1v hinnalla
    discount: "", // Percentage for liittymismaksut or vaihtomaksut
    validFrom: "",
    validTo: "",
    user_id: "",
    membershipDuration: "",
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const defaultDiscountState = {
    code: "",
    type: "pysyvä",
    category: "",
    discount: "",
    validFrom: "",
    validTo: "",
    user_id: "",
  };

  // Fetch discounts
  useEffect(() => {
    axios
      .get(`${apiUrl}/auth/discounts`)
      .then((response) => {
        if (Array.isArray(response.data.discounts)) {
          setDiscounts(response.data.discounts);
          console.log("discounts", response.data.discounts);
        } else {
          console.error("Invalid discounts data:", response.data);
          setDiscounts([]); // Fallback to an empty array
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching discounts:", error);
        setDiscounts([]); // Ensure it's an array
        setLoading(false);
      });
  }, []);

  // Fetch users
  useEffect(() => {
    axios
      .get(`${apiUrl}/auth/users`)
      .then((response) => {
        if (Array.isArray(response.data.users)) {
          setUsers(response.data.users);
        } else {
          console.error("Invalid users data:", response.data);
          setUsers([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setUsers([]); // Ensure it's an array
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "type") {
      setNewDiscount((prev) => ({ ...prev, [name]: value }));
    } else if (name === "category") {
      setSelectedOption(value);
      setNewDiscount((prev) => ({
        ...prev,
        category: value,
        discount: value === "3v 1v hinnalla" ? 0 : prev.discount, // Set discount to 0 for 3v 1v hinnalla
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewDiscount((prev) => ({
      ...prev,
      [name]: value === "null" ? null : value, // Convert "null" to actual null
    }));
  };

  const handleSubmit = () => {
    // Transform the newDiscount object to match the backend's expected format
    const payload = {
      ...newDiscount,
      user_id: isGlobal ? null : newDiscount.user_id,
      membershipDuration: newDiscount.membershipDuration || null, // Ensure membershipDuration is sent properly
    };

    axios
      .post(`${apiUrl}/auth/add-discount`, payload) // Send the transformed payload
      .then((response) => {
        console.log("Discount added successfully:", response.data);

        // Update the discounts list with the newly added discount
        setDiscounts((prev) => [...prev, response.data]);

        // Reset the form
        setNewDiscount(defaultDiscountState);

        // Reset selected option
        setSelectedOption("");
      })
      .catch((error) => {
        console.error("Error adding discount:", error);

        // Display an error message to the user
        alert(error.response?.data?.error || "Failed to add discount");
      });
  };

  const deleteDiscount = (discount) => {
    axios
      .delete(`${apiUrl}/auth/delete-discount/${discount.id}`)
      .then((response) => {
        console.log("Discount deleted successfully:", response.data);

        // Update the discounts list by removing the deleted discount
        setDiscounts((prev) => prev.filter((d) => d.id !== discount.id));
      })
      .catch((error) => {
        console.error("Error deleting discount:", error);

        // Display an error message to the user
        alert(error.response?.data?.error || "Failed to delete discount");
      });
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="bg-white rounded-lg shadow-lg p-6 m-4">
          <p className="font-bold text-2xl text-teal-600 m-4 text-center">
            Lisää uusi alennuskoodi
          </p>
          <div className="flex flex-row m-4 flex-wrap">
            <div className="flex flex-col justify-center m-2">
              <p className="text-center">Koodi</p>
              <input
                type="text"
                name="code"
                value={newDiscount.code}
                onChange={handleInputChange}
                className="border rounded p-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex flex-col justify-center m-2">
              <p className="text-center">Tyyppi</p>
              <select
                name="type"
                value={newDiscount.type}
                onChange={handleInputChange}
                className="border rounded p-2 focus:outline-teal-600"
              >
                <option value="pysyvä">Pysyvä</option>
                <option value="kertakäyttö">Kertakäyttö</option>
              </select>
            </div>
            <div className="flex flex-col justify-center m-2">
              <p className="text-center">Kategoria</p>
              <select
                name="category"
                value={newDiscount.category}
                onChange={handleChange}
                className="border rounded p-2 focus:outline-teal-600"
              >
                <option value="">Valitse</option>
                <option value="Liittymismaksut">Liittymismaksut</option>
                <option value="Vaihtomaksut">Vaihtomaksut</option>
              </select>
            </div>
            {(newDiscount.category === "Liittymismaksut" ||
              newDiscount.category === "Vaihtomaksut") && (
              <div className="flex flex-col justify-center m-2">
                <p className="text-center">Alennus</p>
                <select
                  name="discount"
                  value={newDiscount.discount}
                  onChange={handleInputChange}
                  className="border rounded p-2 focus:outline-teal-600"
                >
                  {[...Array(100)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}%
                    </option>
                  ))}
                </select>
              </div>
            )}
            {newDiscount.category === "Liittymismaksut" && (
              <div className="flex flex-col justify-center m-2">
                <p className="text-center">Jäsenyys</p>
                <select
                  name="membershipDuration"
                  value={newDiscount.membershipDuration || ""}
                  onChange={handleInputChange}
                  className="border rounded p-2 focus:outline-teal-600"
                >
                  <option value="">Valitse jäsenyys</option>
                  <option value="12">12 kuukautta</option>
                  <option value="36">36 kuukautta</option>
                  <option value="60">60 kuukautta</option>
                </select>
              </div>
            )}
            <div className="flex flex-col justify-center m-2">
              <p className="text-center">Voimassa alkaen</p>
              <input
                type="date"
                name="validFrom"
                value={newDiscount.validFrom}
                onChange={handleInputChange}
                className="border rounded p-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex flex-col justify-center m-2">
              <p className="text-center">Voimassa asti</p>
              <input
                type="date"
                name="validTo"
                value={newDiscount.validTo}
                onChange={handleInputChange}
                className="border rounded p-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex flex-col justify-center m-2">
              <p className="text-center">Käyttäjä</p>
              <select
                name="user_id"
                value={newDiscount.user_id}
                onChange={handleInputChange}
                className="border rounded p-2 focus:outline-teal-600"
              >
                <option value="">Valitse käyttäjä</option>
                <option value="null">Kaikki</option>
                {users.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.email}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              className="bg-teal-600 text-white p-2 m-2 rounded hover:bg-teal-500"
            >
              Lisää alennus
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="bg-white rounded-lg shadow-lg p-6 m-4">
          <p className="font-bold text-2xl text-teal-600 m-4 text-center">
            Kaikki alennuskoodit
          </p>
          {loading ? (
            <p>Ladataan...</p>
          ) : discounts.length > 0 ? (
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border border-gray-300 px-4 py-2">Koodi</th>
                  <th className="border border-gray-300 px-4 py-2">Tyyppi</th>
                  <th className="border border-gray-300 px-4 py-2">
                    Kategoria
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Alennus</th>
                  <th className="border border-gray-300 px-4 py-2">Jäsenyys</th>
                  <th className="border border-gray-300 px-4 py-2">
                    Voimassa alkaen
                  </th>
                  <th className="border border-gray-300 px-4 py-2">
                    Voimassa asti
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Käyttäjä</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(discounts) &&
                  discounts.map((discount) => (
                    <tr key={discount.id}>
                      <td className="border border-gray-300 px-4 py-2">
                        {discount.code}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {discount.type}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {discount.category}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {discount.discount}%
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {discount.membershipDuration
                          ? `${discount.membershipDuration} kuukautta`
                          : "Kaikki"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {new Date(discount.validFrom).toLocaleDateString(
                          "fi-FI"
                        )}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {new Date(discount.validTo).toLocaleDateString("fi-FI")}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {users.find((user) => user.user_id === discount.user_id)
                          ?.email || "N/A"}
                      </td>
                      <button
                        className="border border-gray-300 px-4 py-2 hover:bg-gray-300"
                        onClick={() => deleteDiscount(discount)}
                      >
                        Poista
                      </button>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p>Ei alennuksia saatavilla.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Discounts;
