import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const ConfirmationModal = ({
  prompt,
  onConfirm,
  onCancel,
  checkBox,
  openTermsModal,
  isBooking,
  errorMessage,
}) => {
  const [showCheckbox, setShowCheckbox] = useState(checkBox);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [attemptedConfirmWithoutCheck, setAttemptedConfirmWithoutCheck] =
    useState(false);

  const [discountCode, setDiscountCode] = useState(""); // State for discount code

  const handleConfirmClick = () => {
    if (showCheckbox && !isCheckboxChecked) {
      setAttemptedConfirmWithoutCheck(true);
      return;
    }
    // Pass the discount code along with confirm event if applicable
    onConfirm(discountCode);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setAttemptedConfirmWithoutCheck(false);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="absolute inset-0 bg-black opacity-50 backdrop-blur-sm"></div>
      <div className="relative bg-white rounded-3xl shadow-lg p-4 w-96 z-100">
        <h2 className="text-lg font-semibold m-2">{prompt}</h2>

        {/* Discount Code Field - Render only for Booking */}
        {isBooking && (
          <div className="flex flex-col m-2">
            <label htmlFor="discountCode" className="font-semibold mb-1">
              Alennuskoodi
            </label>
            {errorMessage && (
              <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
            )}
            <input
              type="text"
              id="discountCode"
              placeholder="Syötä alennuskoodi"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              className="border rounded-lg p-2 focus:outline-teal-600"
            />
          </div>
        )}

        <div className="flex flex-col justify-center mt-2">
          <div className="flex flex-row justify-center">
            <button
              className="bg-teal-600 text-white rounded-lg p-2 w-24 m-2 hover:bg-teal-700"
              onClick={handleConfirmClick}
            >
              Kyllä
            </button>
            <button
              className="bg-red-600 text-white rounded-lg p-2 w-24 m-2 hover:bg-red-700"
              onClick={onCancel}
            >
              Ei
            </button>
          </div>

          {showCheckbox && (
            <FormGroup>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <span
                    className={`${
                      attemptedConfirmWithoutCheck
                        ? "text-red-500"
                        : "text-black"
                    }`}
                  >
                    Olen lukenut{" "}
                    <span
                      className="text-blue-500 cursor-pointer hover:underline"
                      onClick={openTermsModal}
                    >
                      käyttöehdot
                    </span>{" "}
                    ja hyväksyn ne
                  </span>
                }
              />
            </FormGroup>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
