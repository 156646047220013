import React, { useState, useEffect } from "react";
import axios from "axios";

const Calculator = () => {
  const [places, setPlaces] = useState(Object.keys(searchOptions));
  const [properties, setProperties] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [credits, setCredits] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [selectedSleepingPlaces, setSelectedSleepingPlaces] = useState("");

  const weekOptions = Array.from({ length: 52 }, (_, i) => i + 1);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setWeeks(weekOptions);
  }, []);

  const handlePlaceChange = (e) => {
    const selectedPlace = e.target.value;
    setSelectedPlace(selectedPlace);
    setProperties(searchOptions[selectedPlace] || []);
    setSelectedProperty(""); // Reset property when place changes
  };

  const handleCalculate = () => {
    axios
      .get(`${apiUrl}/timeshares/`)
      .then((response) => {
        const data = response.data;
        const flattenedData = Object.values(data).flat();
        const result = flattenedData.find(
          (item) =>
            item.Lomakohde === selectedProperty &&
            item.Viikko === parseInt(selectedWeek) &&
            item.Nukkumapaikat === parseInt(selectedSleepingPlaces)
        );
        setCredits(result ? result.Krediitit : "Not found");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="p-4">
      <h2 className="text-lg lg:text-xl font-bold mb-8 text-center">
        Lomakrediittilaskuri
      </h2>
      <p className="text-sm lg:text-md mb-4 text-center">
        Laskurilla voit tarkistaa, kuinka paljon lomakrediittejä saat
        osakkeestasi
      </p>
      <section className="p-4 mx-auto bg-white rounded-xl shadow-lg space-y-4 w-6/7 md:w-1/2">
        <div className="mb-4">
          <label className="block text-sm lg:text-md font-semibold mb-2">
            Valitse sijainti
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={selectedPlace}
            onChange={handlePlaceChange}
          >
            <option value="">Sijainti</option>
            {places.sort().map((place, index) => (
              <option key={index} value={place}>
                {place.charAt(0).toUpperCase() + place.slice(1)}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm lg:text-md font-semibold mb-2">
            Valitse kohde
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={selectedProperty}
            onChange={(e) => setSelectedProperty(e.target.value)}
            disabled={!selectedPlace}
          >
            <option value="">Kohde</option>
            {properties.map((property, index) => (
              <option key={index} value={property}>
                {property}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm lg:text-md font-semibold mb-2">
            Valitse viikko
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={selectedWeek}
            onChange={(e) => setSelectedWeek(e.target.value)}
          >
            <option value="">Viikko</option>
            {weeks.map((week, index) => (
              <option key={index} value={week}>
                {week}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm lg:text-md font-semibold mb-2">
            Valitse nukkumapaikkojen määrä
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-lg"
            value={selectedSleepingPlaces}
            onChange={(e) => setSelectedSleepingPlaces(e.target.value)}
          >
            <option value="">Nukkumapaikat</option>
            {[2, 4, 6, 8].map((places, index) => (
              <option key={index} value={places}>
                {places}
              </option>
            ))}
            <option value="10">10 tai enemmän</option>
          </select>
        </div>
        <div className="flex justify-center">
          <button
            className="w-full bg-teal-600 text-white p-2 rounded-2xl hover:bg-teal-700 transition duration-200"
            onClick={handleCalculate}
          >
            Laske
          </button>
        </div>
      </section>
      {credits !== null && (
        <section className="p-4 bg-gray-100 rounded-lg mt-4">
          <h3 className="text-xl font-semibold mb-4">
            {credits} Lomakrediittiä
          </h3>
        </section>
      )}
      <p className="text-sm m-4 text-center">
        Pidätämme oikeuden muuttaa osakkeiden krediittiarvoja tarpeen mukaan.
        Näihin muutoksiin voivat vaikuttaa muun muassa kohteen kysyntä, kohteen
        kunto, tarjotut palvelut sekä muut oleelliset tekijät, jotka vaikuttavat
        kohteessa lomailuun. Tavoitteenamme on varmistaa osakkeiden
        krediittiarvojen vastaavan aina kohteen ajankohtaista tilannetta ja
        tarjota näin mahdollisimman reilu ja joustava hinnoittelu.
      </p>
    </div>
  );
};

const searchOptions = {
  airisto: ["Airiston Fregatti", "Airiston Kuunari", "Airiston Kutteri"],
  ellivuori: ["Ellin Loisto I"],
  kivijärvi: ["Hannunkiven Lomakylä"],
  himos: [
    "Himoksen Aurinkopaikka",
    "Himoksen Tähti I",
    "Villas Himos I",
    "Villas Himos II",
    "Villas Himos III",
  ],
  hoilola: ["Onnenvirta II", "Onnenvirta III"],
  ikaalinen: ["Ikaalisten Mäntypiha"],
  imatra: ["Imatra Spa Viikko Oy"],
  kalajoki: [
    "Särkkäin Lomaparatiisi I",
    "Särkkäin Lomaparatiisi II",
    "Rantabeach",
    "Kalajoen Keidas",
  ],
  vuokatti: [
    "Katinkullan Golfharju",
    "Katinkullan Hiekkaniemi",
    "Katinkullan Kiinteistöt",
    "Katinkultaniemi",
    "Katinkultaranta",
    "Katinkullan Rantahovi",
    "Katinkulta Residence",
    "Katinkullan Golfpuisto",
    "Katinkulta Spa Lodge 1",
    "Katinkulta Spa Lodge 2",
    "Villas Katinkulta Spa 1",
    "Villas Katinkulta Spa 1 Lodge",
    "Villas Katinkulta Spa 2",
    "Villas Katinkulta Golf Park",
    "Vuokatti Country Club",
    "Vuokatin Kulta-Katti",
    "Vuokatin Lepokatti",
  ],
  koli: ["Kolin Kukkula"],
  kihniö: [
    "Pyhäniemi II",
    "Pyhäniemi III",
    "Pyhäniemi IV",
    "Pyhäniemi V",
    "Pyhäniemi VI",
    "Pyhäniemi VII",
    "Pyhäniemi VIII",
  ],
  kuortane: ["Kuortaneen Liikuntahotelli"],
  kuusamo: [
    "Kuusamon Lampitropiikki",
    "Kuusamon Tähti 1",
    "Kuusamon Lomaparatiisi",
    "Kuusamon Rantatropiikki",
    "Kuusamon Lomatropiikki",
    "Kuusamon Rantatropiikki 2",
    "Petäjälampi 6 Lodge",
    "Petäjälammenranta 7 Lodge",
  ],
  laukaa: ["Pitkäniemi III"],
  levi: [
    "Levi-Rakkavaara Club 1",
    "Rakkavaara Club Int. Ltd",
    "Abgott",
    "Aruudenia",
  ],
  naantali: ["Naantalin kylpyläranta", "Sunborn Vacation Club 1"],
  punkaharju: ["Hiekkaharju 1", "Hiekkaharju 2"],
  pyhä: ["Onninpyhä", "Pyhänhovi", "Pyhä HolySuites"],
  rönnäs: ["Rönnäs Country Club"],
  ruka: ["Rukan Lomakylä I", "RukaVillage Suites 1"],
  saimaa: [
    "Anttilankaari 6",
    "Anttilankaari 8",
    "Anttilankaari 10",
    "Vipelentie 35",
    "Saimaanranta",
    "Saimaanranta 2",
    "Saimaanranta 3",
    "Saimaanrantapuisto",
    "Saimaan Keskuspuisto Lodge",
    "Saimaa Pearl Lodge 1",
    "Saimaa Spa Lodge 1",
    "Saimaa Spa Lodge 2",
    "Saimaa Spa Lodge 3",
  ],
  salla: [
    "Sallas huoneistot",
    "Sallan tähti",
    "Sallatunturin Kelorinne",
    "Sallan Eraustähti",
    "Villas Sallatunturi 1",
    "Villas Sallatunturi 2",
  ],
  saariselkä: [
    "Kermikkä",
    "Siulaselkä",
    "Kelotirro",
    "Riekonraito",
    "Laavutieva",
    "Tirrolampi",
    "Nilihonka",
    "Saariselkä Spa Lodge 2",
    "Kelotähti 1 Lodge",
    "Ruskarinne",
  ],
  tahko: [
    "Tahkotime",
    "Leppätahko",
    "Nilsiän Rentotahko",
    "Spa Suites Black",
    "Spa Suites White",
  ],
  tampere: [
    "Näsijärven Kimallus",
    "Lapiinniemi I",
    "Lapinniemi II",
    "Lapinniemi III",
    "Lapinniemi IV",
    "Lapinniemi V",
    "Lapinniemi VI",
    "Lapinniemi VII",
    "Lapinniemi VIII",
    "Lapinniemi IX",
    "Lapinniemi X",
    "Lapinniemi XI",
    "Lapinniemi XII",
    "Lapinniemi XIII",
    "Lapinniemi XIV",
    "Lapinniemi XV",
    "Lapinniemi XVI",
    "Lapinniemi XVII",
    "Lapinniemi XVIII",
    "Lapinniemi XIX",
    "Lapinniemi XX",
  ],
  turku: ["Caribia Spa Lodge 1", "HC Villas Turun Caribia"],
  vierumäki: [
    "HC Villas Vierumäki 1",
    "HC Villas Vierumäki 2",
    "Vierumäki Golf Resort",
  ],
  ylläs: [
    "Kolarin Siepakka",
    "Kesänki",
    "Kuer",
    "Muiro",
    "Musko",
    "Mokko",
    "Ylläksen Rautamajat",
    "Ylläs Saaga",
    "Ylläs viikko 1",
    "Ylläksen lomaviikot",
  ],
  ähtäri: ["Ähtärin Lomakylä", "Moksunhonka 1"],
  ulkomaat: [
    "Jardin Amadores",
    "Playa Amadores",
    "Puerto Calma",
    "Sol Amadores",
    "Vista Amadores",
    "Åre",
  ],
};

export default Calculator;
